<template>
    <div class="main_container">
        <div class="main_page">
            <div class="page_title">{{ $route.meta.title }}</div>
            <div class="page_containt">
                <div class="page_search">
                    <el-form ref="searchForm" :model="searchForm">
                        <el-row type="flex">
                            <el-col class="search_col">
                                <el-form-item size="small" prop="month">
                                    <el-date-picker size="small" style="width: 120px" v-model="searchForm.month"
                                        type="month" value-format="yyyy-MM" :clearable="false">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col class="search_col">
                                <el-button type="primary" size="small" native-type="submit"
                                    @click.native.prevent="handleSearch">搜索</el-button>
                            </el-col>
                            <el-col class="search_col">
                                <el-button type="primary" size="small" :loading="btnLogining"
                                    @click="handleExcel" plain>导出</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="page_box" ref="pageBox">
                    <el-table v-loading="loading" size="small" class="el_table" cell-class-name="el_table_cell"
                        header-row-class-name="el_table_header" :data="listData" :height="tableHeight" style="width: 100%">
                        <el-table-column prop="created_at" label="日期" width="120">
                        </el-table-column>
                        <el-table-column prop="recharge_money" label="充值总金额">
                        </el-table-column>
                        <el-table-column prop="consume_money" label="销售总金额">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import reportApi from "@/api/report";
import util from "@/lib/util";
export default {
    data() {
        return {
            loading: false,
            btnLogining: false,
            searchForm: {
                month: ''
            },
            listData: [],
            tableHeight: 500,
        };
    },
    mounted() {
        onTableHeight(this, false);
        this.searchForm.month = util.getMonth();
        this.getList();
    },
    methods: {
        //查询
        handleSearch() {
            this.getList();
        },
        //列表
        getList() {
            this.loading = true;
            reportApi
                .saleList({
                    ...this.searchForm,
                })
                .then((res) => {
                    this.listData = res.list;
                })
                .catch((res) => { })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleExcel() {
            let message = this.$message({
                message: "请求中",
                iconClass: "el-icon-loading",
                customClass: "message-loading",
                duration: 0,
                offset: 60,
            });
            this.btnLogining = true;
            reportApi
                .saleExcel({
                    ...this.searchForm
                })
                .then((res) => {
                    message.close();
                    const link = document.createElement("a");
                    const blob = new Blob([res], { type: "application/vnd.ms-excel" }); //设置文件流
                    let url = URL.createObjectURL(blob);
                    link.style.display = "none";
                    link.href = url;
                    link.download = this.searchForm.month+"销售报表";
                    document.body.appendChild(link);
                    link.click();
                    URL.revokeObjectURL(url);
                    this.btnLogining = false;
                })
                .catch((res) => {
                    message.close();
                    this.btnLogining = false;
                });
        }
    },
};
</script>